import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Login from './Login';
import { selectLogin } from './loginSlice';
import Profile from './Profile'

function isNonEmpty(str) {
    return str !== undefined && str !== null && str.trim().length > 0;
}

const Account = () => {
    const loginData = useSelector(selectLogin);
    const alreadyLoggedIn = isNonEmpty(loginData.id) && isNonEmpty(loginData.token) && loginData.verified;
    const content = alreadyLoggedIn ? <Profile loginData={loginData} /> : <Login />;
    return content;
};

export default Account;