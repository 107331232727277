import 'antd/dist/antd.css';
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import Footer from './components/Footer';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';

//<Login />
//<Link to="/shop">Shop</Link>
//<Link to="/help">Help</Link>

function App() {
  return (
    <Layout>
      <Header />
      <Breadcrumbs>
        <Outlet />
      </Breadcrumbs>
      <Footer />
    </Layout>
  );
}

export default App;
