import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { auth } from './loginAPI';

const initialState = {
    id: "",
    token: "",
    name: "",
    email: "",
    expiration: 0,
    verified: false,
    status: 'idle'
};

export const authAsync = createAsyncThunk(
    'login/auth',
    async (id, token) => {
        const response = await auth(id, token);
        return response.data;
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state, action) => {
            state = { ...state, ...action.payload }
        },
        logout: (state) => {
            state = initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(authAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.verified = action.payload.verified
            })
            .addCase(authAsync.rejected, (state, action) => {
                // TODO: error
                state = { ...initialState, status: 'failed', verified: false };
            });;
    },
});

export const { login, logout } = loginSlice.actions;

export const selectLogin = (state) => state.login;

export const attemptLogin = (payload) => (dispatch, getState) => {
    dispatch(login(payload))
    dispatch(authAsync(payload.id, payload.token));
};

export default loginSlice.reducer;
