import { Form, Input, Button, Checkbox } from 'antd';

const Help = () => {
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const form = <Form
        name="help"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout='vertical'
    >
        <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email' }]}
        >
            <Input />
        </Form.Item>

        <Form.Item
            label="Order Number"
            name="order_number"
            rules={[{ required: false, message: 'Please input your order number if applicable to your issue' }]}
        >
            <Input />
        </Form.Item>

        <Form.Item
            label="Issue Description"
            name="issue"
            rules={[{ required: true, message: 'Please input a description of your issue' }]}
        >
            <Input.TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </Form>

    return (
        <div>
            <h2>Help Form</h2>
            {form}
        </div>
      );
}

export default Help