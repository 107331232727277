import { Menu, Layout } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import Account from "../features/login/Account";

const menuItemData = [
    { key: "1", link: "/", name: "Home" },
    { key: "2", link: "/shop", name: "Shop" },
    { key: "3", link: "/help", name: "Help" }
];

function isCurrentPathSelected(buttonPath, actualPath) {
    if (buttonPath === "/") return (actualPath === "/")
    else return actualPath.startsWith(buttonPath.trim())
}

const Header = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const currentKeys = menuItemData.filter(data => isCurrentPathSelected(data.link, currentPath)).map(data => data.key)

    return <Layout style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Menu theme="dark" mode="horizontal" >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{ padding: '0px 10px'}}>
                    <h2>
                        <Link to="/" style={{ color: '#9e6dcf' }}>
                            Pigeonette
                        </Link>
                    </h2>
                </div>

                <div style={{ marginLeft: 'auto', padding: '0px 10px' }}>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={currentKeys}>
                        {
                            menuItemData.map(data => {
                                return <Menu.Item key={data.key} style={{ float: 'left' }}>
                                    <NavLink to={data.link}>
                                        {data.name}
                                    </NavLink>
                                </Menu.Item>
                            })
                        }
                    </Menu>
                </div>

                <div style={{ marginLeft: 'auto', padding: '0px 10px' }}>
                    <Account />
                </div>
            </div>
        </Menu>
    </Layout>
};

export default Header;