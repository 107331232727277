import { Button } from 'antd';
import React from 'react';
import { GoogleLogin } from 'react-google-login';

class Login extends React.Component {

  responseGoogle = (response) => {
    console.log(response);
  }

  render() {
    return (
      <div>
        <GoogleLogin clientId="298878662484-1chemalcqfavqkrs7h7n9jt232jrv4ig.apps.googleusercontent.com"
          render={renderProps => (
            <Button onClick={renderProps.onClick} disabled={renderProps.disabled}>Login With Google</Button>
          )}
          buttonText="Login With Google"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    );
  }

}

export default Login;
