import { Layout, Breadcrumb } from "antd";
import { NavLink, useLocation } from "react-router-dom";

const HomeBreadcrumb =
    <Breadcrumb.Item key={-1}>
        <NavLink to="/">
            Home
        </NavLink>
    </Breadcrumb.Item>

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Breadcrumbs = ({ children }) => {
    const location = useLocation();
    const paths = location.pathname.split("/").map(path => path.trim()).filter(path => path.length > 0)
    const crumbs = paths.map((path, idx) => {
        const prevPaths = paths.slice(0, idx + 1)
        const fullPath = "/" + prevPaths.join("/")
        return <Breadcrumb.Item key={idx}>
            <NavLink to={fullPath}> {capitalizeFirstLetter(path)} </NavLink>
        </Breadcrumb.Item>
    })
    crumbs.unshift(HomeBreadcrumb)

    return <Layout className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
            {crumbs}
        </Breadcrumb>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
            {children}
        </div>
    </Layout>
};

export default Breadcrumbs;