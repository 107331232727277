import Layout from "antd/lib/layout/layout"
import { NavLink } from "react-router-dom";

const Footer = () => {
    return <Layout style={{ textAlign: 'center', alignItems: 'center' }}>
        Pigeonette ©2022
        <div style={{ display: 'flex' }}>
            <div style={{ padding: '0px 10px' }}>
                <NavLink to="/privacy">Privacy Policy</NavLink>
            </div>
            <div style={{ padding: '0px 10px' }}>
                <NavLink to="/terms">Terms And Conditions</NavLink>
            </div>
        </div>
    </Layout>
};

export default Footer;