import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar } from 'antd';

const Profile = (props) => {
    const loginData = props.loginData;
    const firstLetter = loginData.name ? loginData.name[0] : "?";

    <Avatar style={{ color: '#9e6dcf', backgroundColor: '#c9ace6' }}>
        {firstLetter}
    </Avatar>

    // TODO: logout, link to account and other stuff
};

export default Profile;